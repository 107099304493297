.interactiveToolTipPopper {
  z-index: 9999999999 !important;
}

.unsetInteractiveToolTipPopper {
  position: relative !important;
}

.overrideMobileNavBackdropStyle {
  height: 81.5px !important;
  bottom: 0 !important;
  top: unset !important;
}

.overrideAppBardropStyle {
  height: 50px !important;
}

.overrideMobileAppBardropStyle {
  height: 64px !important;
}

.overrideMenuBackdropStyle {
  width: inherit !important;
}

.tooltipContainer {
  z-index: 999999 !important;
  position: relative !important;
  overflow-y: hidden !important ;
  background: white !important;
  pointer-events: none;
}

.pointerNone {
  pointer-events: none !important;
}

.gettingStartedTooltioContainer {
  z-index: 999999 !important;
  overflow-y: hidden !important ;
  background: white !important;
  pointer-events: none;
}
.subMenu {
  position: relative;
  padding: 10px 0px 10px 63px !important;
}

.subMenu::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 36px;
  width: 2px;
  background-color: #cfcdc9;
}

.wrapperInner {
  padding: 0px 0 13px 0px;
}

.hidebefore::before {
  background-color: rgb(207, 205, 201, 0.3) !important;
}

.scrollMenu {
  position: relative;
  z-index: 9999;
  overflow-y: scroll;
}

.scrollMenu::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 767px) {
  .subMenu {
    padding: 10px 0 10px 48px !important;
  }
  .subMenu::before {
    left: 26.75;
  }

  .interactiveToolTipPopper {
    left: 32px !important;
  }
  .interactiveToolTipPopperBottomNavigation {
    z-index: 9999999999 !important;
  }
}
