.root {
  padding: 0px !important;
  border-radius: 5px !important;
  height: 31.63px !important;
  width: 31.63px !important;
  min-width: 31.63px !important;
  box-sizing: border-box;
  border: 1.5px solid #cfcdc9 !important;
}

.inactive {
  background: #fff !important;
}

.inactive svg {
  font-size: 16px;
  color: #00ab71;
}

.active {
  border-color: #00ab71 !important;
  background: #00ab71 !important;
}

.active svg {
  font-size: 16px;
  color: white;
}

.disabled {
  opacity: 0.72 !important;
}
