.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.icon {
  color: #d9d9d9;
  width: 24px !important;
  height: 24px !important;
  pointer-events: none;
}

.activeIcon {
  cursor: pointer;
  color: #493ab1;
  pointer-events: unset;
}
.navIconsWrapper {
  width: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -8px;
}

.btn {
  font-size: 14px;
  height: 32px !important;
  min-width: unset !important;
  width: 68px;
}
